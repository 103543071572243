<template>
	<div class="detail">
    <Modal v-model="showCancelTips" confirmText="联系客服" title="提示" zIndex="100" @confirm="contactKF">
      该订单快递员已接单，请联系客服取消，接单24小时后还未取件可手动取消
    </Modal>
    <a id="goOnlineService" style="display: none;">在线客服</a>
    <Modal v-model="isShowLast" :hideFooter="true" :zIndex="110" class="last">
      <div class="last-blacktitle">已开通快递100寄件服务</div>
      <div class="last-show">
        <img src="https://cdn.kuaidi100.com/images/better/openBest.png" />
      </div>
      <div class="last-btn" style="margin-top:0;">
        <button @click="isShowLast = false">我知道了，继续下单</button>
      </div>
    </Modal>
    <Modal v-model="showPayTips" confirmText="我知道了" type="alert" title="已确认价格" zIndex="100">
      快递单号在第一条有途中的物流信息时会由微信支付分发起扣款
    </Modal>
    <div class="tips" v-if="showTips && detail.wechatpayfail !== 'Y' && detail.paywayen">
      <div class="tips-sign">!</div>
      <div class="tips-desc">本单已使用免密支付，切勿线下付款给快递员，如有 争议可拨打客服热线<span @click="bindTelTap('400-000-0387')">（400-000-0387）</span>解决</div>
      <span class="tips-close" @click="showTips = false">+</span>
    </div>
    <div class='kdnu-box' @click='toQueryNu(detail.kuaidiCom, detail.kuaidiNum)'
      v-if="JSON.stringify(detail) !== '{}' && detail.tabId != 4 && detail.tabId != 7">
      <div>
        {{detail.kuaidiComName}}单号： 
        <span v-if="detail.kuaidiNum">{{detail.kuaidiNum}}</span> 
        <span v-else style="color:#bebebe;">暂无，取件后显示</span>
        <img v-if="detail.kuaidiNum" @click.stop="copyExpid1" src="https://cdn.kuaidi100.com/images/rebuild/copy_nu.png">
        <div style="position: fixed;top:-9999px;left: -9999px;" class="copytxt1">{{detail.kuaidiNum}}</div>
      </div>
    </div>
    <div class="gcode-box" v-if="detail.tabId == 3">
      <div class='code-info'>
        <div class='nu-value'>
          <div class='label'>取件码</div>
          <div class='code-value'>{{detail.gotcode}}</div>
        </div>
        <div class='code-help' @click='isShowCodeTips = true'></div>
      </div>
      <div class='code-image' @click="showBigCode = true" v-if="!detail.gotaddr2">
        <img :src="`https://www.kuaidi100.com/twoCode.do?code=${detail.gotcode}&type=barcode&h=74&w=536`">
      </div>
    </div>
    <!--状态-->
    <div class='ostatus-box' v-if="JSON.stringify(detail) !== '{}'">
      <div class="ostatus-card">
        <img class="ostatus-card-listen" src="https://cdn.kuaidi100.com/images/rebuild/listen.png" @click="toHelpCenter">
        <div class='card-title'>{{detail.tabIdName}}</div>
        <div class='card-tips' 
          :class="[detail.tabId == 12 ? 'blue' : '']">{{statusTips}}<span v-if="detail.disdoortime < 0 && detail.tabId <= 3" class='s-remind'>已超时</span>
        </div>
        <div class='card-desc' v-if="detail.statusDesc">{{detail.statusDesc}}</div>
        <div class='card-time' v-if="detail.tabId==1 && showWaitTime">已经等待
          <span class='time'>{{waitTime}}</span>
        </div>
      </div>
      <div class="cour-info">
        <div class='info-left'>
          <div class='info-avatar'>
            <img 
              :src="mktInfo.logo || 'https://cdn.kuaidi100.com/images/rebuild/image1/icoKuaidi100Courier.png'">
          </div>
          <div class='info-detail'>
            <div class='name'>
              {{mktInfo.mktName}}
              <div class="name-tips" v-if="detail.paywayen">取件后，请在快递100支付运费</div>
            </div>
          </div>
        </div>
        <div class='info-tel' v-if="mktInfo && detail.tabId != '12'" @click="bindTelTap(courierinfo ? courierinfo.mobile : mktInfo.phone)">
          <img src='https://cdn.kuaidi100.com/images/rebuild/image1/icoPhone.png'>
        </div>
      </div>
      <!--广告-->
      <div class="newAd" v-if="bannerPos">
        <bannerBar :pos="bannerPos"></bannerBar>
      </div>
      <!--支付-->
      <div class='pay-box' 
        v-if="(detail.paystatus === 'WAITPAY' && detail.wechatpayfail !== 'Y' && detail.payway != 'WXAPP_PAYAFTER') || detail.paystatus === 'WAITCONFIRM'">
        <div class='pay-detail'>
          <div class='d-num'>需支付<span>{{detail.price}}</span>元</div>
          <div class='d-td' @click='toPayDetail(1)'>查看明细</div>
        </div>
        <div class='paybtn-s' @click="toPayDetail(1)">确认并支付</div>
      </div>
      <!--后端发起支付，但是微信还没扣，即为扣款中-->
      <div class="pay-box"
        v-if="detail.tabId === '10' && detail.paystatus !== 'PAYED' && detail.paywayen && detail.paystatus !== 'WAITCONFIRM' && detail.price">
        <div class="pay-detail">
          <div class='d-num d-num-w'>
            <div>
              <div v-if="detail.paystatus == 'PAYAFTER'">需支付{{detail.price}}元</div>
              <div v-if="detail.wechatpayfail === 'Y'"><span>{{detail.price || detail.pprice}}</span>元扣款中</div>
            </div>
            <div class='paybtn-tips' @click="showPayTips = true">
              <img src='https://cdn.kuaidi100.com/images/rebuild/image/ico_help.png'>如何完成支付？</div>
          </div>
          <div class="d-td" @click="toPayDetail(2)">查看明细</div>
        </div>
      </div>
      <!--详情-->
      <div class="order-box">
        <div class="order-card-tip" v-if="detail.payway == 'WXAPP_PAYAFTER'">本单用微信支付分下单并在快递员取件后结算</div>
        <div class="order-card">
          <div class="order-info">
            <div class="addr">{{overText(detail.sendcity)}}</div>
            <div class="name">{{overText(detail.sendName)}}</div>
          </div>
          <div class='order-status'>
            <div class="order-status-pay">
              <div v-if="detail.paystatus == 'PAYED'">已支付<span>{{detail.price}}</span>元</div>
            </div>
            <!-- <div :class="[detail.backStatus == 0 ? 'status0' : 'status']" v-if="detail.payStatusText && detail.paystatus != 'PAYED'">{{detail.payStatusText}}</div> -->
            <div class="status-icon"></div>
            <div class="order-status-check" @click="toPayDetail(2)" v-if="detail.paystatus == 'PAYED'">查看明细</div>
            <div class="order-status-open" :style="{marginTop: detail.paystatus == 'PAYED' ? '0.3125rem' : '1.125rem'}" @click="orderDetailShow = !orderDetailShow" v-if="!orderDetailShow">
              <span>点击展开订单详情</span>
              <img class="order-status-arrow"  src="https://cdn.kuaidi100.com/images/rebuild/image/collapse.png">
            </div>
          </div>
          <div class='order-info'>
            <div class='addr'>{{overText(detail.reccity)}}</div>
            <div class='name'>{{overText(detail.recName)}}</div>
          </div>
        </div>
        <div class="detail-item" :class="[detail.tabId == 0 ? 'br8' : '']" v-if="orderDetailShow">
          <div class="item">
            <div class="item-label">寄件信息：</div>
            <div class="item-content">
              <div class="item-content-name">
                {{ detail.sendName }} 
                {{ detail.sendmobile ? detail.sendmobile : detail.sendtel | encryptionPhone(isShowSendPhone) }}
                <img class="item-content-eyes" :src="eyesSrc(isShowSendPhone)" @click="togglePhone('isShowSendPhone')"/>
              </div>
              <div class="contact-addr">{{clearSign(detail.sendxzq)+detail.sendaddr}}</div>
            </div>
          </div>
          <div class="item">
            <div class="item-label">收件信息：</div>
            <div class="item-content">
              <div class="item-content-name">
                {{ detail.recName }} 
                {{ detail.recmobile ? detail.recmobile : detail.rectel | encryptionPhone(isShowRecPhone)}}
                <img class="item-content-eyes" :src="eyesSrc(isShowRecPhone)" @click="togglePhone('isShowRecPhone')"/>
              </div>
              <div class="contact-addr">{{clearSign(detail.recxzq)+detail.recaddr}}</div>
            </div>
          </div>
          <div class="item" v-if="detail.doorTime">
            <div class="item-label">预约时间：</div>
            <div class="item-content">{{detail.doorTime}}</div>
          </div>
          <div class="item" v-if="detail.gotaddr">
            <div class="item-label">取件位置：</div>
            <div class="item-content">{{detail.gotaddr}}</div>
          </div>
          <div class="item" v-if="detail.paystatus == 'PAYED'">
            <div class="item-label">已付运费：</div>
            <div class="item-content">{{detail.price}}元</div>
          </div>
          <div class="item" v-if="detail.payment">
            <div class="item-label">付费方式：</div>
            <div class="item-content">{{detail.payment == 'SHIPPER' ? '寄付' : (detail.payment == 'MONTHLY' ? '月结' : '到付')}}</div>
          </div>
          <div class="item" v-if="detail.valinspay&&detail.valinspay!=0">
            <div class="item-label">保价金额：</div>
            <div class="item-content flex">{{detail.otherValins ? detail.otherValins : detail.valinspay == 0 ? '不保价' : detail.valinspay}}
              <img @click="goUrl" v-if="detail.otherValins === '丢必赔'" src="https://cdn.kuaidi100.com/images/better/question.png">
            </div>
            <div class='item-copy' @click="claim" v-if="detail.tabId >= 5 && detail.tabId != 7">理赔申请</div>
          </div>
          <div class="item">
            <div class="item-label">物品信息：</div>
            <div class="item-content">{{detail.cargo}}/{{detail.weight || 1}}公斤</div>
          </div>
          <div class="item" v-if="detail.comment">
            <div class="item-label">留言：</div>
            <div class="item-content">{{detail.comment}}</div>
          </div>
          <div class="item">
            <div class="item-label">订单号：</div>
            <div class="item-content">{{detail.expid}}</div>
            <div class='item-copy' @click='copyExpid'>复制</div>
            <div class="copytxt" style="position: fixed;top:-9999px;left: -9999px;">{{detail.expid}}</div>
          </div>
          <div class="item" v-if="detail.servicetype">
            <div class="item-label">服务类型：</div>
            <div class="item-content">{{detail.servicetype}}</div>
          </div>
          <div class="item">
            <div class="item-label">下单时间：</div>
            <div class="item-content">{{detail.created}}</div>
          </div>
          <div class="item" v-if="detail.source">
            <div class="item-label">下单来源：</div>
            <div class="item-content">{{detail.source}}</div>
          </div>
        </div>
        <div class="order-expand" v-if="orderDetailShow" @click='orderDetailShow = !orderDetailShow'>
          <div>点击收起订单详情</div>
          <img src='https://cdn.kuaidi100.com/images/rebuild/image/collapse.png'>
        </div>
      </div>
    </div>
    <!--官方tabId是字符串，优选的是数字-->
    <div class="btnstatus" v-if="!isShowBtn">
      <button @click="goCancel" style="background: #fff;color: #333;"
        v-if="['0', '1', '3'].includes(detail.tabId)">取消订单</button>
      <button @click="toComplain"
        v-if="detail.tabId === '3' && detail.disdoortime < 0">{{detail.complaint == 'N' ? '投诉' : '投诉进度'}}</button>
      <button @click="urgeOrder"
        v-if="detail.tabId === '3' && detail.disdoortime < 0">催单</button>
      <button @click="bindTelTap(courierinfo ? courierinfo.mobile ? courierinfo.mobile : mktInfo.phone : mktInfo.phone)"
        v-if="['1', '3'].includes(detail.tabId)">联系快递员</button>
      <!-- <button @click="toElectronic" v-if="detail.paystatus === 'PAYED' && detail.kuaidiNum">电子存根</button> -->
      <button @click="reSend('sendAgain')"
        v-if="detail.kuaidiNum || ['5', '6', '10'].includes(detail.tabId)">再下一单</button>
      <button @click="reSend('')"
        v-if="['4', '7'].includes(detail.tabId)">重新下单</button>
    </div>
  </div>
</template>

<script>
import Modal from "components/Modal/Modal"
import util from "lib/util"
import encryptPhoneMixin from 'lib/encryptPhoneMixin'
export default {
  name: 'dispatchOfficial',
  mixins: [encryptPhoneMixin],
  components: {
    Modal
  },
  data () {
    return {
      showCancelTips: false,
      isShowShare: false,
      bannerPos: '',
      auth: 1,
      isShowLast: false,
      showPayTips: false,
      showTips: true,
      evalStatus: 0,
      sendNameChange: '',
      expid: '',
      openBest: '0',
      detail: {},
      isMoreOpt: false,
      gInterval: null,
      gInterval1: null,
      statusTips: '',
      courierinfo: null,
      waitTime: '', // 等待时间
      showWaitTime: false,
      mktInfo: null,
      orderDetailShow: false,
      modifySendAddr: {},
      isShowCodeTips: false,
      showBigCode: false,
      evaData: {},
      evaData2: {},
      evaAry: [{
        v: '穿着/谈吐不得体',
        s: false
      }, {
        v: '服务态度恶劣',
        s: false
      }, {
        v: '没有按时上门',
        s: false
      }, {
        v: '多/乱收费',
        s: false
      }, {
        v: '乱回填上门时间',
        s: false
      }, {
        v: '电话索要取件码',
        s: false
      }],
      erInput: '',
      ec: [],
      eci: [],
      orderTime: '',
      overTime: false,
      // #ifdef MP-BAIDU || MP-WEIXIN
      popPos: ''
      // #endif
    }
  },
  computed: {
    isShowBtn () {
      return (this.detail.tabId === '10' && this.detail.paystatus !== 'PAYED' && this.detail.paywayen && this.detail.price) || this.detail.paystatus === 'WAITPAY' || this.detail.paystatus === 'WAITCONFIRM'
    }
  },
  created () { // onLoad
    this.expid = this.$route.query.expid
    this.sign = this.$route.query.sign
    ;(function(a,h,c,b,f,g){a["UdeskApiObject"]=f;a[f]=a[f]||function(){(a[f].d=a[f].d||[]).push(arguments)};g=h.createElement(c);g.async=1;g.charset="utf-8";g.src=b;c=h.getElementsByTagName(c)[0];c.parentNode.insertBefore(g,c)})(window,document,"script","//assets-cli.udesk.cn/im_client/js/udeskApi.js","ud");
    ud({
        "code": "298f17ga",
        "link": "https://kuaidi100.udesk.cn/im_client/?web_plugin_id=48852",
        targetSelector: "#goOnlineService",
        selector: "#uDeskTarget"
    });
  },
  mounted () { // onShow
    this.login(() => {
      this.getOrderInfo(1)
    })
  },
  methods: {
    contactKF () {
      document.getElementById('goOnlineService').click()
    },
    overText (str, num = 6) {
      if (str && str.length > num) {
        return str.substring(0, num) + '...'
      } else {
        return str
      }
    },
    toElectronic() {
      this.$router.push({
        name: "normalReceipt",
        query: {
          sign: this.sign,
          expid: this.expid,
          stampid: this.detail.stampid || ''
        }
      })
    },
    toPayDetail (index = 1) {
      this.$router.push({
        name: "dispatchOfficialPay",
        query: {
          paywayen: this.detail.paywayen || '',
          optBtn: index,
          expid: this.expid,
          sign: this.detail.sign,
          paystatus: this.detail.paystatus,
          com: this.detail.kuaidiCom,
          price: this.detail.price
        }
      })
    },
    //帮助中心
    toHelpCenter () {
      location.href = "https://m.kuaidi100.com/help"
    },
    getTime () {
      let detail = this.detail
      var orderTime = ''
      var overTime = false
      var createtime = detail.createtime
      var nowtime = new Date().getTime();
      var day = parseInt((nowtime - createtime) / (24 * 60 * 60 * 1000))
      var q = (nowtime - createtime) % (24 * 60 * 60 * 1000)
      var hour = parseInt(q / (60 * 60 * 1000))
      var w = (nowtime - createtime) % (60 * 60 * 1000)
      var min = parseInt(w / (60 * 1000))
      var s = (nowtime - createtime) % (60 * 1000)
      if (day == 0) {
        if (min == 0) {
          min = 1
        }
        orderTime = '已经下单' + hour + '小时' + min + '分'
        if (hour >= 6) overTime = true
      } else if (day > 0) {
        overTime = true
        orderTime = '已经下单' + day + '天' + hour + '小时'
      }
      if (!overTime) {
        this.gInterval1 = setTimeout(()=> {
          this.getOrderInfo()
        }, 60 * 1000);
      }
      this.statusTips = orderTime
      this.overTime = overTime
    },
    autoComNum () {
      this.statusTips = '正在查询最新物流状态...'
      let url = '/autonumber/autoComNum'
      const params = {
        data: {
          text: this.detail.kuaidiNum
        },
        outside: true,
        handleFail: false
      }
      this.$http.post(url, params).then((res)=> {
        if (res.auto && res.auto.length > 0) {
          this.query(res.auto[0].comCode)
        } else {
          this.statusTips = '物流信息好像迷路了，请稍后再查询'
        }
      }).catch(()=> {
        this.statusTips = '物流信息好像迷路了，请稍后再查询'
      })
    },
    query (com) {
      this.login(()=> {
        let url = '/query'
        const params = {
          data: {
            postid: this.detail.kuaidiNum,
            id: 1,
            valicode: '',
            temp: 0.45404207542606256, 
            type: com,
            phone: '',
            platform: 'MWWW'
          },
          handleFail: false,
          outside: true,
          accessToken: true
        }
        this.$http.post(url, params).then((res)=> {
          if(res.data[0]) {
            this.statusTips = (res.ischeck == '0' ? '在途中：' : '已签收：') + this.overText(res.data[0].context, 13)
          } else {
            this.statusTips = '暂无物流信息'
          }
        }).catch(()=> {
          this.statusTips = '物流信息好像迷路了，请稍后再查询'
        })
      })
    },
    setOrderStutas () {
      let detail = this.detail
      let tabId = detail.tabId
      let transStatusName = detail.transStatusName || '暂无物流信息'
      switch (tabId) {
        case ('0'): { // 等待接单
          this.getTime()
          break
        }
        case ('1'): { // 已接单
          if(detail.doorTime) {
            this.statusTips = '预约时间：' + detail.doorTime
          } else {
            this.statusTips = '请保持电话畅通，以便取件时联系'
          }
          break
        }
        case ('4'): { // 快递员取消
          this.statusTips = "取消时间：" + (detail.lastupDate || '--')
          detail.statusDesc = '取消原因:' + detail.cancelmsg
          break;
        }
        case ('5'): { // 在途中
          this.autoComNum() // 查物流
          break;
        }
        case ('6'): { // 已签收
          this.statusTips = "已签收：签收时间" + (detail.lastupDate || '--')
          break;
        }
        case ('7'): { // 用户取消
          this.statusTips = "取消时间：" + (detail.lastupDate || '--')
          detail.statusDesc = '取消原因:' + detail.cancelmsg
          break;
        }
        case ('10'): { // 已取件
          this.statusTips = "取件时间：" + (detail.lastupDate || '--')
          break;
        }
        case ('12'): { // 改派
          this.statusTips = '查看改派后的订单 >'
          break;
        }
        default: {
          this.statusTips = ''
        }
      }
    },
    claim (url) { // 查看计费明细
      location.href = 'https://m.kuaidi100.com/app/frame/claim.jsp'
    },
    toQueryNu (com, nu) { // 查询物流信息
      if(!nu) {
        return
      }
      let phone = this.detail.sendmobile ? this.detail.sendmobile.substring(7, this.detail.sendmobile.length) : ''
      location.href = `https://m.kuaidi100.com/result.jsp?nu=${nu}&com=${com}&lastfour=${phone}`
    },
    urgeOrder () {
      let url = '/apicenter/order.do?method=reminder'
      const params = {
        data: {
          expid: this.expid
        }
      }
      this.$http.post(url,params).then((res)=> {
        this.$toast('已发送催单请求')
        setTimeout(()=> {
          clearInterval(this.gInterval)
          this.getOrderInfo()
        }, 2500)
      })
    },
    toComplain () {
      this.$confirm({
        content: "投诉和查看投诉进度功能需要下载快递100APP，是否前往下载？",
        confirmText: "是",
        cancelText: "否"
      }, () => {
        util.downApp()
      })
    },
    bindTelTap (num) {
      window.location.href = `tel:${num}`
    },
    goUrl (url) {
      this.$router.push({
        name: "dispatchDbp"
      })
    },
    reSend (type) {
      console.log('type', type)
      const detail = this.detail
      const sendxzq = detail.sendxzq ? detail.sendxzq.split('#') : ''
      const sendman = type === 'modify' ? this.modifySendAddr : {
        name: detail.sendName,
        phone: detail.sendmobile,
        fixedPhone: detail.sendtel,
        address: detail.sendaddr,
        xzqName: `${detail.sendxzq.split('#').join(',')}`
      }
      const recxzq = detail.recxzq ? detail.recxzq.split('#') : ''
      const recman = {
        name: detail.recName,
        phone: detail.recmobile,
        fixedPhone: detail.rectel,
        address: detail.recaddr,
        xzqName: `${detail.recxzq.split('#').join(',')}`
      }
      localStorage.setItem('dispatch_sendman', JSON.stringify(sendman))
      if (type !== 'sendAgain') {
        localStorage.setItem('dispatch_recman', JSON.stringify(recman)) // sendAgain = 再下一单
        if (detail.tabId === 4 || detail.tabId === 7) {
          const params = {
            cargo: detail.cargo,
            weight: detail.weight
          }
          localStorage.setItem('dispatch_orderData', JSON.stringify(params))
        }
      } else {
        localStorage.removeItem('dispatch_recman')
        localStorage.removeItem('dispatch_orderData')
      }
      this.$router.replace({
        name: "dispatch",
        query: {
          orderSource: 'sendAgain'
        }
      })
    },
    goCancel () { // 取消订单
      if (this.detail.cancelable === 'Y') {
        this.$router.push({
          name: "cancel",
          params: {
            order: this.detail
          }
        })
      } else {
        this.showCancelTips = true
      }
    },
    copyExpid () { // 复制
      const range = document.createRange();
      range.selectNode(document.querySelector(".copytxt"));
      const selection = window.getSelection();
      if(selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
      this.$toast('复制成功')
    },
    copyExpid1 () { // 复制
      const range = document.createRange();
      range.selectNode(document.querySelector(".copytxt1"));
      const selection = window.getSelection();
      if(selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
      this.$toast('复制成功')
    },
    clearSign (str) {
      if(str) {
        return str.replace(/#/g, ' ')
      }
    },
    checkCom (index, arr) {
      return arr.indexOf(index) > -1
    },
    getOrderInfo (tag) { // 订单详情
      let url = '/apicenter/order.do?method=getOrderInfo'
      const params = {
        data: {
          sign: this.sign,
          expid: this.expid,
          apiversion: 19
        }
      }
      this.$http.post(url, params).then((res)=> {
        this.detail = res.detail
        this.mktInfo = res.mktInfo
        this.courierinfo = res.courierinfo
        if (this.detail.iseval == 0 && this.detail.tabId >= 3 && this.detail.paystatus == 'PAYED' && tag == 1) {
          this.evalStatus = 1
        }
        this.setOrderStutas()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin common-block {
    background: #fff;
    border-radius: 0.25rem;
    margin: 0.625rem 10px 0;
  }
  .newAd {
    @include common-block;
    background: #efeff4;
  }
  .select-item::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.25rem;
    margin-top: -0.1875rem;
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    border-top: 0.09375rem solid #c4c4c4;
    border-right: 0.09375rem solid #c4c4c4;
    transform: rotate(45deg);
  }
  .flex {
    display: flex;
    align-items: center;
  }
  /*订单信息*/
  .detail {
    display: flex;
    flex-direction: column;
    background: #efeff4;
    min-height: 100vh;
    overflow: auto;
    .tips {
      background: #fff;
      border-radius: 0.25rem;
      margin: 0.625rem 10px 0;
      display: flex;
      align-items: center;
      height: 2.75rem;
      position: relative;
      &-sign {
        width: 1rem;
        height: 1rem;
        background: #ff7f02;
        color: #fff;
        border-radius: 50%;
        margin-left: 0.625rem;
        text-align: center;
        font-size: 0.75rem;
        line-height: 1rem;
      }
      &-desc {
        font-size: 0.75rem;
        color: #878787;
        padding-left: 0.5rem;
        width: 80%;
        text {
          color: #317ee7;
        }
      }
      &-close {
        font-size: 1.25rem;
        color: #c8c8c8;
        transform: rotate(45deg);
        position: absolute;
        right: 0.625rem;
        top: 0.4375rem;
      }
    }
    .btnstatus {
      height: 4.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: #efeff4;
      border-top: 1px solid #efeff4;
      z-index: 80;
      padding: 0 0.25rem;
      button {
        text-align: center;
        flex: 1;
        margin: 0 0.4375rem;
        font-size: 1.0625rem;
        color: #333;
        line-height: 3rem;
        border-radius: 0.25rem;
        background: #ffffff;
        border: 1px solid #ddd;
        overflow: unset;
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          background: #ff7f02;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
.order-box {
  position: relative;
  background-color: #fff;
  margin-left: 0.625rem;
  z-index: 70;
  margin: 0.625rem 0.625rem 4.0625rem;
  border-radius: 0.25rem;
}
.br8{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.order-card {
  height: 8.125rem;
  border-radius: 0.125rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.order-info {
  text-align: center;
  width: 33%;
  margin-top: 1.875rem;
}

.order-info .addr {
  font-size: 1.0625rem;
  color: #333;
}

.order-info .name {
  font-size: 0.875rem;
  color: #888;
  margin-top: 0.5625rem;
}

.order-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 1.5rem;
  &-pay {
    font-size: 0.875rem;
    color: #878787;
    height: 1.25rem;
    text {
      color: #ff7f02;
    }
  }
  &-check {
    color: #317ee7;
    font-size: 0.875rem;
    margin-top: 1px;
  }
  &-open {
    font-size: 0.8125rem;
    color: #888;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0.625rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &-arrow {
    width: 0.8125rem;
    height: 0.8125rem;
    transform: rotate(180deg);
    margin-left: 0.1875rem;
  }
}

.order-status .status {
  font-size: 0.875rem;
  color: #888;
  display: flex;
  align-items: baseline;
}
.order-status .status0{
  font-size: 0.875rem;
  color: #ff7f02;
  display: flex;
  align-items: baseline;
}
.order-status .status-icon {
  position: relative;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAAH1pC8nAAAAAXNSR0IArs4c6QAAA/xJREFUWAmtVk1oE0EUdrdb21iNXqwIjQhSaL2oGLQNJSYVRKg/FQQRPagXexBBL8WD4KGHXjx4EEFE8CAIYquCoAiNTZsiEumpreJF8aAiWGltk5im6/e2eduXySTdlQ4k8733vvfNm9nZ2VmzptiGh4cPMTYTicQpMgqFwit2GgSGhoZs12EYPxmXBBxnMpncx1GTwMLCwiWkXyVsSB1yOA2jTjMu6Ymty3BLMgzjmW3bm5EVoUzYT9DZjY2NZwzIHobRFI/H7wH3g9gLwizsIJHLGkh5DFcoC0gHSF+lTdiZJzsxTBNU7rPtEDSzOC8JvDDPUSAE7OMUBH6J7g9+cxY50I4huITwD0wzXGpyCMLSJobZ2dnpLFyRT/LLUkTgAPdYqBJfSTaTqJdDqaNIHuESRSU4ybYUZJ/szZGRkV3FSX6WAczprrSrCbm7SCb4xRb2Qz0/Ezl3+E/C/1gKhkKh+ubm5pz0EaY1WY/Sb0uBIqmn2DsdOCGdAAU9PZ2ampoDaEkp6hnjqAh5JuuIWJMknkhKF1N92ulAoAWLOkVkrMUL7NAjaqK0tZutrq7uC5Mg1sW4Uq8ViUQiGZmAyvqlrWKTdiJIg2pA2qimV9oqdioBqXtsbCygBqWNgc5KW2I6F6chsgnOHBaRjhhqcNndS3D5H3HnQC16bNiJWCx2h0QGkHBimeoPmaZ5gaaz1V9aKRsFvKaDcT+5Udoj7IfTTFFffcS/I64d0ISSs+GkAAspfatiu6aJEfrwgm1zPRoATh6D/NaEHJeF4PVKQfbX1tbuYazrtTs2lUrtkOSOjo4JaatYK7K4uPiLiZhKnLHvnnZwOp2u9ZKoPQoqJZLozMwMfaO3CM48qt2Ltf0gfL6hdkkqqWQyGTotZRFEXYffJC5JO8n43+ZrRWgQ8c6rY/61LGt7NBr9pga82PT5s7C0R/ELNDQ0PA2Hw/MrJL5H/KCGsxY3xanR0dEQduasJl7VRW9wF3bnAK6uD/H851BY2WkmFVAwFVKpbczn8xNeN6gUMYPB4BvpQGGDKKZP+iReoRA6l0OYUFrmeMHOHsHA4xDY7SVhtTmYWBrf2ii/NR9XewCveliAcC6Xu+jcoWHE1ERUKg9keSu2wN+g8jU2nYp56MhcByOfvprz6N7h43ADt69P9NbQPcY9G0CYDgQCrW1tbT804nTBvgz/LV2MfdC4hgOu6i2EudzTq9uOQthO4tseg891cED0YYHLIHJv+i2CRHwfaFgR+nppT1EU8QBFnCNhv81XIfQRy2az2gMPRax4Pa1WHL811ThuDIdVi2sIgCLeYiWq3pEFXQt9FYLdPY5Be6DkrApwFr8rKKJdq+7D+Q8Ja51sAS9ceAAAAABJRU5ErkJggg==') center no-repeat;
  background-size: 1.0625rem 0.9375rem;
  width: 1.0625rem;
  height: 1.0625rem;
  margin: 0.3125rem 0;
}

.order-status .status-icon::after {
  content: '';
  position: absolute;
  width: 3.375rem;
  height: 1px;
  background-color: #efeff4;
  left: 1.375rem;
  top: 0.5rem;
}

.order-status .status-icon::before {
  content: '';
  position: absolute;
  width: 3.375rem;
  height: 1px;
  background-color: #efeff4;
  right: 1.375rem;
  top: 0.5rem;
}

.order-expand {
  display: flex;
  margin-top: 1.0625rem;
  font-size: 0.8125rem;
  color: #888;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.9375rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  
}
.order-expand img{
  width: 0.8125rem;
  height: 0.8125rem;
  margin-left: 0.3125rem;
}
.detail-item {
  background: #fff;
  border-top: 1px solid #eee;
}

.detail-item .item {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem;
}
.detail-item .item:first-child{
  margin-top: 1.0625rem
}
.detail-item .item:last-child {
  padding-bottom: 0.625rem;
}

.detail-item .item-label {
  font-size: 0.875rem;
  margin-left: 1.1875rem;
  color: #888;
  width: 4.6875rem;
}

.detail-item .item .item-content {
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-width: 15.625rem;
  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.625rem;
  }
}

.detail-item .item .item-copy {
  font-size: 0.875rem;
  color: #3581eb;
  margin-left: 0.625rem;
}
.contact-addr{
  color: #888;
}
.more-opt {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.9375rem;
  height: 2.625rem;
  justify-content: center;
  align-items: center;
}

.more-opt img {
  width: 1.375rem;
  height: 1.375rem;
}

.more-btn {
  position: absolute;
  width: 6.25rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
  right: 0.625rem;
  top: 2.1875rem;
  z-index: 111;
}

.more-btn .item {
  height: 3rem;
  line-height: 3rem;
}

.more-btn:last-child {
  border-bottom: 0;
}

.more-btn .ico-guide {
  position: absolute;
  margin-top: -0.15625rem;
  border-width: 0 0.3125rem 0.3125rem;
  border-color: transparent transparent rgba(0, 0, 0, 0.6);
  z-index: 101;
  margin-left: 5.125rem;
  border-style: solid;
  top: -0.1875rem;
}
/*评价modal*/

.efloat {
  animation: efloatfadeIn 0.2s;
}

.modalFade {
  animation: fadeIn 0.2s;
}

@-webkit-keyframes fadeIn {
  0% {
    bottom: -15.625rem;
  }
  100% {
    bottom: 0;
  }
}

@-webkit-keyframes efloatfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.efloat-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.emodal-title {
  position: relative;
  width: 100%;
  height: 2.75rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 1.0625rem;
  color: #333;
  display: flex;
  justify-content: center;
}

.modal-tcancle {
  position: absolute;
  width: 3.125rem;
  font-size: 0.9375rem;
  color: #888;
  left: 0.9375rem;
}

.modal-tcomplete {
  font-size: 0.9375rem;
  color: #3581eb;
  position: absolute;
  width: 3.125rem;
  right: 0.9375rem;
}

.ei-selected {
  border: 1px solid #3581eb;
  color: #3581eb;
}

.sendchoose {
  padding: 0.9375rem 1.1875rem 0 1.1875rem;
}

.sendchoose-kd {
  border-top: 1px solid #eee;
  padding-bottom: 0.9375rem;
}

.sendchoose-kd>view:first-child {
  display: flex;
  align-items: center;
  min-height: 4.0625rem;
}

.sendchoose-kd.cantuse {
  opacity: 0.4;
}

.sendchoose-kd-img {
  width: 14%;
  text-align: center;
}

.sendchoose-kd-img img{
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
}

.sendchoose-kd-name {
  width: 50%;
}

.sendchoose-kd-name-detail {
  display: flex;
  align-items: center;
  margin-top: -0.125rem;
}

.sendchoose-kd-name-detail text:first-child{
  font-size: 0.9375rem;
  color: #333;
}

.sendchoose-kd-name-detail .best{
  font-size: 0.625rem;
  color: #fff;
  background: #317ee7;
  border-radius: 0.25rem;
  padding: 0 0.1875rem;
  margin-left: 0.3125rem;
  height: 0.9375rem;
  line-height: 0.9375rem;
}

.sendchoose-kd-name-detail .label{
  font-size: 0.625rem;
  color: #888;
  border-radius: 0.25rem;
  padding: 0 0.1875rem;
  margin-left: 0.3125rem;
  height: 0.9375rem;
  line-height: 0.9375rem;
  border: 1px solid #bebebe;
}

.sendchoose-kd-money {
  flex: 1;
}

.sendchoose-kd-money-top {
  text-align: right;
}

.sendchoose-kd-money-top .noserver {
  font-size: 0.75rem;
  color: #888;
}

.sendchoose-kd-money-top .origin {
  font-size: 0.75rem;
  color: #bebebe;
  text-decoration: line-through;
  margin-right: 0.3125rem;
}

.sendchoose-kd-money-top .true {
  font-size: 1.0625rem;
  color: #333;
}

.sendchoose-kd-money-top .unit {
  font-size: 0.8125rem;
  color: #333;
}

.sendchoose-bottom {
  display: flex;
  padding: 0.625rem 0;
}

.sendchoose-bottom-left {
  width: 30%;
}


.sendchoose-bottom-left-weight text{
  vertical-align: middle;
}

.sendchoose-bottom-left-weight .num {
  color: #ff7f02;
  font-size: 1.25rem;
}

.sendchoose-bottom-left-weight .unit {
  color: #878787;
  font-size: 0.75rem;
  margin-left: 0.1875rem;
}

.sendchoose-bottom-left-weight .img {
  width: 0.625rem;
  height: 0.4375rem;
  margin-left: 0.3125rem;
}

.sendchoose-bottom-left-desc {
  font-size: 0.75rem;
  color: #878787;
  margin-top: -0.125rem;
}

.sendchoose-bottom-right {
  flex: 1;
}

.sendchoose-bottom-right button {
  width: 15.625rem;
  height: 2.75rem;
  font-size: 1.0625rem;
  line-height: 2.75rem;
  border-radius: 0.25rem;
  background: #ff7f02;
  color: #fff;
  text-align: center;
}

.showNewCom .virtual-input {
  display: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}

.black {
  color: #333;
}

.gotaddr-tips text{
  color: #ff7f02;
}

.gotaddr {
  box-shadow: unset;
  margin-left: 0;
  position: relative;
  border: 0.125rem solid rgba(0, 0, 0, 0.1);
  width: unset;
}

.gotaddr.chooseNow {
  border: 0.125rem solid rgba(49, 126, 231, 0.3);
}

.gotaddr-choose{
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  top: 50%;
  margin-top: -0.625rem;
  right: 0.625rem;
}

  .tips-part1 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .ico_notice {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAMAAACTisy7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABCFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAACQWfHNAAAAVnRSTlMAkt8eT+VNKjproRYFQC++Xo0oSom3uW8T7S2P9yUzwfF8teEXBH3GH3DnGZULDZMVAlfqR+zEP8rPLvpZmoP4Yq0jNKOoSPRgnciyUT4i1XZqZUSgjg4jqQ4AAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAA+ElEQVQoz3WS6VbCMBBGPwEFCoiAyKIgKCAKVBTUll1WFRRZnPd/FHoorUna3j/5ztzM5OQkAMuRyw1HXORxlsd0Yql5fYfglwJ6CIZMSXQqbA/TmREjRFFtiZ3HL6REUksSpdLmxsurDLLXtCeXB25uC8yYIkpkclcWDrknhgfeVYijatRrcg145CXVAVl+QkKLz2gIsokXole8KarSKrYF2UFXVXv6aLkvyAF7k5ylk+FdkENWjngX5i865qT45JN/lZpaHtU/O7h4AVaSH59fyryXtf8k9ZZn8f1j75r61KV94+9qvR6UNvatCG23f3BiTvpnMtgBpGNWYfNXv20AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTctMDgtMTBUMTE6MzY6MTMrMDg6MDB9NKsQAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE3LTA4LTEwVDExOjM2OjEzKzA4OjAwDGkTrAAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-size: 0.875rem 0.8125rem;
    width: 0.875rem;
    height: 0.8125rem;
    display: flex;
    align-self: baseline;
  }
  /*取件码*/

  .gcode-box {
    
    background-color: #fff;
    margin: 0.625rem 0.625rem 0 0.625rem;
    border-radius: 0.25rem;
  }

  .code-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3.5rem;
    align-items: center;
  }

  .code-info .nu-value {
    display: flex;
    align-items: center;
  }

  .nu-value .label {
    font-size: 0.875rem;
    color: #888;
    margin-left: 0.9375rem;
  }

  .code-value {
    font-size: 1.25rem;
    color: #333;
    letter-spacing: 0.9375rem;
    width: 15.9375rem;
    text-align: center;
  }
  .code-tips{
    display: border-box;
    padding: 0.9375rem 0.9375rem 1.25rem 0.9375rem;
  }
  .code-tips .code-tips-t{
    font-size: 0.875rem;
    color: #333;
  }
  .code-tips .code-tips-c{
    font-size: 0.8125rem;
    color: #888;
    margin-top: 0.1875rem;
  }
  .code-tips .code-tips-c text{
    color: #ff7f02
  }
  .code-img {
    display: flex;
    justify-content: center;
    height: 2.3125rem;
    padding-bottom: 1.25rem;
  }

  .code-image img {
    width: 16.75rem;
    height: 2.3125rem;
  }

  .code-help {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAilBMVEUAAADAwMDS0tK/v7++vr6+vr6/v7/CwsLNzc3AwMC/v7/AwMC/v7+/v7+/v7+/v7+/v7/AwMDBwcHCwsLDw8PFxcW/v7+/v7+/v7++vr6/v7+/v7/BwcHCwsLMzMy/v7+/v7+/v7+/v7+/v7+/v7+/v7/BwcHBwcHDw8O+vr6/v7/AwMC/v7++vr7s9odLAAAALXRSTlMAUQPs6eO2GgpZj2r70aF4Z2AyKxIO9NnCnYBEIh8Iy7ytpJaISzgnFvNyQKjjV4i7AAABVUlEQVQoz2VT17aDMAwzTcIou5RdNpQu///v3VNILobqJXaEFCs5AEFsvca8CMoT/OIUtkl39sNiyNjnfmQ1N9NMWRuB6OYdO6YabWufT8R4QPPgduHWf+1lv9PcUPm9GqnVvEa041Xq2WWdhBnLavaIToLI3itvZcuSj2t7RvGu4aajkHr3a1+xemkiJ6mWqVMMVrp0ASDo18ZGT7kMMtD3VF1Tu74qPJXIBuAPeVdzvGq4+g7CHE7OIfMLEymASYdK7NkAMVR15MKD7Vgbne027w3AzvyOqJFn7ACEQegMC3pQDtDb5OkSvBFatwA0fesjpJOYzxggZsamflwJHS5C/0PcqZgvL3oVF7VTsnSTF53c5GqenOSyxFVVT0PGTltTZXYiUPDFDHvYu4QTO8ekjXS3Aopq4Oe3nNjquV3DAXPRcFf32vSp28SJwLhMYRnRP+IPN0MWj+Qc2B0AAAAASUVORK5CYII=') center no-repeat;
    background-size: 0.9375rem;
    width: 2.5rem;
    height: 0.9375rem;
  }
  .image-show{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444444;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  .image-show img{
    width: 100%;
  }
  /*订单状态*/

  .ostatus-box {
    border-radius: 0.25rem;
    margin-bottom: 8rem;
  }

  .ostatus-card {
    position: relative;
    padding: 1.875rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: 0.625rem 0.625rem 0;
    border-radius: 0.25rem;
    &-listen {
      width: 1.375rem;
      height: 1.375rem;
      position: absolute;
      top: 2.1875rem;
      right: 0.9375rem;
    }
    .card-title {
      font-size: 1.3125rem;
      color: #333;
    }
    .card-tips {
      font-size: 0.875rem;
      color: #888;
      margin-top: 0.625rem;
    }
    .card-tips.blue {
      color: #317ee7;
    }
    .card-desc {
      font-size: 0.875rem;
      color: #333;
      margin-top: 0.625rem;
      padding: 0 0.9375rem;
      text-align: center;
    }
    .card-time {
      font-size: 0.875rem;
      color: #333;
      margin-top: 0.375rem;
    }
  }

  .bbr0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .time {
    color: #ff7f02;
  }

  /*快递员信息*/

  .cour-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 4.6875rem;
    background-color: #fff;
    margin: 0 10px;
    border-top: 1px solid #efeff4;
    align-items: center;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .bb0 {
    border-bottom: 0;
  }

  .info-left {
    display: flex;
    height: 3.375rem;
    align-items: center;
  }

  .info-avatar {
    margin-left: 0.9375rem;
  }

  .info-avatar img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  .info-detail {
    margin-left: 0.9375rem;
    .name {
      font-size: 1.0625rem;
      color: #333;
      &-tips {
        font-size: 0.75rem;
        color: #888;
      }
    }
  }
  .info-tel {
    margin-right: 0.9375rem;
  }

  .info-tel img {
    width: 1.375rem;
    height: 1.375rem;
  }

  /*按钮*/

  .btn-box {
    position: fixed;
    height: 3rem;
    z-index: 99;
    background-color: #efeff4;
    width: 100%;
    padding: 0.625rem 0 0.9375rem 0;
    bottom: 0;
  }

  .btn-box .cancle-btn {
    height: 3rem;
    background-color: #fff;
    margin:0 10px;
    font-size: 1.0625rem;
    color: #888;
    line-height: 3rem;
    text-align: center;
    border: 1px solid #ddd;
  }

  .btn-box .btn-strong {
    
    height: 3rem;
    background-color: #ff7f02;
    margin-left: 0.625rem;
    font-size: 1.0625rem;
    color: #fff;
    line-height: 3rem;
    text-align: center;
  }

  .got-btns {
    
    display: flex;
    justify-content: space-between;
    margin-left: 0.625rem;
    font-size: 1.0625rem;
    line-height: 3rem;
    text-align: center;
  }

  /*paybox*/

  .pay-box {
    height: 5rem;
    margin: 0.625rem 0.625rem 0;
    // margin-top: 0.625rem;
    background-color: #fff;
    // margin-left: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pay-detail {
    margin-left: 0.9375rem;
  }

  .pay-detail .d-num {
    font-size: 1.3125rem;
    color: #333;
  }

  .d-num-w {
    display: flex;
    justify-content: space-between;
    width: 21.25rem;
  }

  .pay-detail .d-num text {
    color: #ff7f02;
  }

  .pay-detail .d-td {
    position: relative;
    font-size: 0.875rem;
    color: #888;
    margin-top: 0.3125rem;
  }

  .d-td::after {
    content: '';
    position: absolute;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAMAAADJev/pAAAAdVBMVEUAAADT09O+vr6/v7/Jycm/v7+/v7+/v7+/v7/AwMC/v7/AwMDCwsK/v7+/v7+/v7+/v7+/v7+/v7++vr6/v7/AwMDAwMDAwMDAwMDBwcHAwMDCwsLBwcG/v7+/v7++vr6+vr7BwcG/v7+/v7/IyMjMzMy+vr6PneG4AAAAJnRSTlMABPf7CunJsod+PTYh39vQuqablpFzaGFaU00nFPLu7HpGMBgXD5MmjVcAAACGSURBVBjTbZDZDoMgFEQHRMWl1VZtbe3qMv//iQY1UcTzwuQEuAs2RBcc8iD1kY8lPd5c33xZ3Ok9974/MRSIKBPbq4CBAnCln269CHl+TSE3YWW9qDLzdMH6upuKzdjNNB+Wc/qb9u2BKnO2b2pnBTWQ+nRWVFHGKJgL7CmZIdEDHMSvHgHlDgi0bdjMVAAAAABJRU5ErkJggg==') right center no-repeat;
    background-color: #fff;
    background-size: 0.75rem 0.4375rem;
    transform: rotate(90deg);
    width: 0.75rem;
    height: 0.4375rem;
    margin-left: 0.3125rem;
    margin-top: 0.375rem;
  }

  .paybtn-s {
    height: 2.1875rem;
    background-color: #ff7f02;
    color: #fff;
    font-size: 1.0625rem;
    text-align: center;
    line-height: 2.1875rem;
    margin-right: 0.625rem;
    padding: 0 0.9375rem;
  }

  .paybtn-tips {
    display: flex;
    align-items: center;
    margin-right: 0.9375rem;
    font-size: 0.8125rem;
    color: #3581eb;
  }

  .paybtn-tips img {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.3125rem;
  }

  /*快递单号卡片*/

  .kdnu-box {
    div {
      margin: 0.625rem 0.625rem 0;
      height: 2.75rem;
      line-height: 2.75rem;
      font-size: 1rem;
      color: #3581eb;
      background-color: #fff;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 1.125rem;
      height: 1.125rem;
      margin-left: 1rem;
    }
  }

  /*评价*/

  .eva-box {
    background-color: #fff;
    
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.625rem;
    margin-left: 0.625rem;
    align-items: center;
    border-radius: 0.25rem;
    position: relative;
  }

  .eva-box .label {
    font-size: 1rem;
    margin-left: 0.625rem;
    color: #888;
  }

  .eva-box .eva-status {
    font-size: 1rem;
    margin-right: 1.875rem;
  }

  .fc3 {
    color: #333;
  }

  .fcb {
    color: #ff7f02;
  }


  .item-t {
    font-size: 1.0625rem;
    color: #3581eb;
  }

  .item-t:last-child {
    margin-left: 4.6875rem;
  }
  .app-ico-close {
    position: absolute;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAgVBMVEUAAADJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycny8vLz8/PNzc309PT////19fX29vbOzs739/cAAACyCj+0AAAAIHRSTlMAAT6JxOj5igJi2yHKQOtGRyfy8wNjP8PCy8woSdyLxZ5AedMAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAA5klEQVQoz32T2xaCIBBFR0xKs4tpdtFyChX6/x8MMgSU2i/OYis44xHgS0DCRURptFiSAFxWcYIjSbyy3TpFh3Rt3GaLE7ZEux3FGXQ/uOyAHvJMuSJFL2khZYw/OMr+kl8yCaDU9ePJ1IW1T71SQqjLtuulZbxr9UoIJ10KLi3jn1sGzhChZfnLchiBNQDx6jpuHFJbspk027L5tifL9Uy4L7R0WxFOK9MhiHaU5b/xyTwc/wweiovfXdUngyr3ubwaolD7YlLrENWzZ/PaxK+6Ts6r3FDfjLq5oVaa3Bv1OzR3Mqo3woZMovX/Uh0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTctMDUtMDlUMTA6MTM6MjQrMDg6MDA4OWzfAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE3LTA1LTA5VDEwOjEzOjI0KzA4OjAwSWTUYwAAAABJRU5ErkJggg==") center no-repeat;
    background-size: 0.875rem;
    height: 0.875rem;
    width: 0.875rem;
  }
  .modal {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
  }
  .s-remind {
    font-size: 0.875rem;
    color: #f00;
    margin-left: 0.625rem;
  }

  /*banner*/

  .ad-banner {
    
    margin-left: 0.625rem;
    height: 4.6875rem;
    margin-top: 0.625rem;
    border-radius: 0.25rem;
  }

  .ad-banner img {
    
    height: 4.6875rem;
    border-radius: 0.25rem;
  }

  .coupon-pop-mask {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .4);
  }
  .coupon-pop-body {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0);
    transition: all .3s;
    text-align: center;
  }
  .coupon-pop-body.show {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }

  .coupon-pop-close {
    width: 2.1875rem;
    height: 4.6875rem;
  }
  .order-card-tip {
    height: 1.5625rem;
    margin: 0.9375rem auto;
    background:rgba(0,0,0,0.03);
    border-radius: 0.125rem;
    font-size: 0.8125rem;
    font-weight:400;
    color:#bebebe;
    line-height: 1.5625rem;
    text-align: center;
  }
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.6;
    z-index: 100;
  }
  .float {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 103;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
</style>
